<template>    
   <section class="features why-choose section-padding bg-img bg-fixed text-white" data-scroll-index="1" v-lazy:background-image="`${publicPath}images/bg2.jpg`">
        <div class="container ontop">
            <div class="row">
                <div class="col-12">
                    <div class="text-center">
                        <h4>We delivered bug free code and cost effective solutions.</h4>
                    </div>    
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-responsive"></i></span>
                        <div class="cont">
                            <h6>Fully Responsive Code</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-settings"></i></span>
                        <div class="cont">
                            <h6>Flexible Structure</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3"> 
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-ui-browser"></i></span>
                        <div class="cont">
                            <h6>Cross Browser Compatible</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3"> 
                    <div class="item">
                        <span class="icon"><i class="icofont-chart-line"></i></span>
                        <div class="cont">
                            <h6>SEO Compatible Website</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-code-alt"></i></span>
                        <div class="cont">
                            <h6>Fully Hand coded</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-layers"></i></span>
                        <div class="cont">
                            <h6>Well Organized</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item mb-40">
                        <span class="icon"><i class="icofont-dashboard"></i></span>
                        <div class="cont">
                            <h6>Speed Performance</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-3 col-xl-3">
                    <div class="item">
                        <span class="icon"><i class="icofont-headphone-alt-1"></i></span>
                        <div class="cont">
                            <h6>Quick Support</h6>
                            <p class="text-light-grey">Nulla metus metus ullamcorper vel tincidunt sed euismod nibh Quisque volutpat condimentum velit class aptent.</p>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </section>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
